// import { createActivityManager } from '@formyevent/activity-manager'
import { defineStore } from 'pinia'
import { computed } from 'vue'

import { useMainStore } from './main.js'

import type { MenuItem } from '../types/index.js'
import type { Activity, Task, Zone, ZoneInterface } from '@formyevent/activity-manager'
// import type { LinkedDataObject } from '@formyevent/model/types'

// import zoneModel from '../config/zone'

export const useActivityStore = defineStore('activity', () => {
  const _mainStore = useMainStore()

  // const activityManager: ActivityManager = createActivityManager(
  //   zoneModel as LinkedDataObject<ZoneModelInterface>,
  //   {} as EntityValidator // You'll need to provide an appropriate EntityValidator
  // )

  // watch(mainStore, () => {
  //   const { user } = mainStore
  //   activityManager.addData(user)
  // })

  const menu = computed<MenuItem[]>(() => {
    // return activityManager.model.zones
    //   .filter((zone) => zone.isValid)
    //   .map(
    //     (zone): MenuItem => ({
    //       label: zone.name,
    //       to: `/zone/${zone.id}`,
    //       icon: zone.icon as IconLabel,
    //       menu: zone.activities
    //         .filter((activity: Activity) => activity.isValid)
    //         .map(
    //           (activity: Activity): MenuItem => ({
    //             label: activity.name,
    //             to: `/zone/${zone.id}/${activity.id}`,
    //             icon: activity.icon as IconLabel,
    //             menu: activity.tasks
    //               .filter((task) => task.isValid)
    //               .map(
    //                 (task): MenuItem => ({
    //                   label: task.name,
    //                   to: `/zone/${zone.id}/${activity.id}/${task.id}`,
    //                   icon: task.icon as IconLabel,
    //                 })
    //               ),
    //           })
    //         ),
    //     })
    //   )
    return []
  })

  function getZone(_id: string): Zone | undefined {
    // return activityManager.model.zones.find((zone) => zone.id === id)
    return undefined
  }

  function getActivity(zoneId: string, _id: string): Activity | undefined {
    const zone = getZone(zoneId)
    if (!zone) {
      return
    }
    // return zone.activities.find((activity) => activity.id === id)
    return undefined
  }

  function getTask(zoneId: string, activityId: string, _id: string): Task | undefined {
    const activity = getActivity(zoneId, activityId)
    if (!activity) {
      return
    }
    // return activity.tasks.find((task) => task.id === id)
    return undefined
  }

  return { zones: [] as ZoneInterface[], menu, getZone, getActivity, getTask }
})
